import { Image } from '@vibTheme/components/_base/Image';
import EditIcon from '@mui/icons-material/Edit';
import { Control, Controller } from 'react-hook-form';
import { ChangeEvent, Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { imageCompressor } from '@utils/imageCompressor';

export type InputImageProps = {
  name: string;
  control: Control<any>;
  defaultValue?: string;
  className?: string;
};

export const InputImage = (props: InputImageProps) => {
  const { control, defaultValue, ...restProps } = props;

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => {
        const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
          if (event.target.files === null) return;

          const compressedImage = await imageCompressor(event.target.files[0]);

          const fileReader = new FileReader();
          fileReader.readAsDataURL(compressedImage);
          fileReader.onload = (e) => {
            field.onChange(e.target?.result);
          };
        };

        return (
          <Fragment>
            <div
              className={twMerge(
                'relative w-full h-[15rem] md:w-[15rem]',
                restProps.className,
              )}
            >
              <Image
                src={field.value}
                alt="Imagem da comunidade"
                className="object-contain"
                fill
              />

              <label
                htmlFor={`${props.name}-filepicker`}
                className="absolute right-sm top-sm text-black/50 hover:cursor-pointer hover:text-black"
              >
                <div className="relative p-sm rounded-full w-[30px] h-[30px] shadow-default bg-gradient-to-r from-slate-100 to-slate-300">
                  <EditIcon className="absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" />
                </div>

                <input
                  id={`${props.name}-filepicker`}
                  type="file"
                  accept="image/*"
                  className="hidden"
                  value=""
                  onChange={handleChange}
                />
              </label>
            </div>
          </Fragment>
        );
      }}
    />
  );
};
