import { UserProduct } from '@interfaces/auth';

export const SELECTED_GROUPS_LOCAL_STORAGE_PREFIX = 'selected-groups';

export function getSelectedGroups(key: string): ProductIds | null {
  const ls = localStorage.getItem(key);
  return ls ? JSON.parse(ls) : null;
}

function deleteSelectedGroups(key: string) {
  localStorage.removeItem(key);
}

function getSelectedGroupsKeys(): string[] {
  return JSON.parse(
    localStorage.getItem(SELECTED_GROUPS_LOCAL_STORAGE_PREFIX) || '[]',
  );
}

function appendToSelectedGroupsKeys(key: string) {
  const selectedGroupsKeys = getSelectedGroupsKeys();
  selectedGroupsKeys.push(key);
  localStorage.setItem(
    SELECTED_GROUPS_LOCAL_STORAGE_PREFIX,
    JSON.stringify(selectedGroupsKeys),
  );
}

export function setSelectedGroups(key: string, ids: ProductIds) {
  localStorage.setItem(key, JSON.stringify(ids));
  appendToSelectedGroupsKeys(key);
}

export function clearSelectedGroups() {
  const keys = getSelectedGroupsKeys();
  keys.forEach((key: string) => deleteSelectedGroups(key));
  deleteSelectedGroups(SELECTED_GROUPS_LOCAL_STORAGE_PREFIX);
}

export function removeIdsFromAllSelectedGroups(ids: ProductIds) {
  const keys = getSelectedGroupsKeys();
  keys.forEach((key) => {
    const selectedGroups = getSelectedGroups(key);
    if (!selectedGroups) return;

    const filteredGroups = selectedGroups.filter((id) => !ids.includes(id));
    setSelectedGroups(key, filteredGroups);
  });
}

type ProductIds = UserProduct['id'][];
