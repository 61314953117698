import { Controller, Control } from 'react-hook-form';
import { styles } from './styles';
import { twMerge } from 'tailwind-merge';
import CloseIcon from '@mui/icons-material/Close';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectProps,
  SelectChangeEvent,
  Chip,
  IconButton,
  FormHelperText,
} from '@mui/material';
import { useEffect } from 'react';
import { useFirstRender } from '@hooks/useFirstRender';

type InputSelectOption = {
  label: string;
  value: any;
  onClick?: () => any;
  'data-testid'?: string;
};

export type InputSelectProps = Omit<SelectProps, 'value'> & {
  type?: 'default' | 'multiple';
  name: string;
  control: Control<any>;
  options: InputSelectOption[];
  onChangeCallback?: (event: SelectChangeEvent) => void;
  helperText?: string;
};

export const InputSelect = ({
  type = 'default',
  name,
  control,
  options,
  onChangeCallback,
  error,
  helperText,
  ...restProps
}: InputSelectProps) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={restProps.defaultValue || control._defaultValues[name]}
      render={({ field }) => {
        const handleChange = (event: any) => {
          field.onChange(event);
          onChangeCallback?.(event);
        };

        // Renderiza um 'Chip' do primeiro elemento selecionado junto a um indicador
        // de quantos elementos foram selecionados quando o tipo do Select é 'multiple'
        const renderValue: SelectProps['renderValue'] = (selected) => {
          if (type === 'default')
            return options.find((option) => option.value === selected)?.label;

          const selectedOptions = options.filter((option) =>
            field.value.includes(option.value),
          );

          return (
            <div className="absolute flex items-center -translate-y-1/2 top-1/2 pr-sm max-w-[calc(100%-3rem)]">
              <Chip
                key={selectedOptions[0]?.value}
                label={selectedOptions[0]?.label}
              />
              {selectedOptions.length > 1 && (
                <span className="text-sm ml-1">
                  +{selectedOptions.length - 1}
                </span>
              )}
            </div>
          );
        };

        const clearSelection = () => {
          handleChange({ target: { value: [] } });
        };

        const clearButton = (
          <IconButton
            aria-label="Clear selected groups"
            onClick={clearSelection}
            className="absolute -translate-y-2/4 top-2/4 right-8 z-40"
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        );

        const isFirstRender = useFirstRender();
        useEffect(() => {
          if (isFirstRender) return;
          handleChange({ target: { value: restProps.defaultValue } });
        }, [restProps.defaultValue]);

        return (
          <FormControl
            className={twMerge(styles, restProps.className)}
            error={error}
          >
            <InputLabel htmlFor={name}>{restProps.label}</InputLabel>
            <Select
              {...restProps}
              inputProps={{ id: name }}
              multiple={type === 'multiple'}
              endAdornment={
                type === 'multiple' && field.value.length > 0 && clearButton
              }
              MenuProps={{ disableScrollLock: true }}
              value={field.value}
              onChange={handleChange}
              renderValue={renderValue}
              className={twMerge(styles, restProps.className)}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={option.onClick}
                  data-testid={option['data-testid']}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText>{helperText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};
